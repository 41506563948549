<template>
    <div>
        <div class="demo-spin-article">
            <div class="row">
                <div class="col-md-4">
                    <div
                        class="tw-bg-gray-100 tw-border-b tw-border-gray-400 tw-p-2 tw-relative tw-text-blue-700"
                    >
                        <b>{{ $t('payrollTemplate.displaySetting') }}</b>
                    </div>
                    <div class="row form-group mb-3 mt-2">
                        <label
                            class="col-md-4 form-col-label control-label required"
                            >{{
                                $t('payrollTemplate.payrollCycleNumber')
                            }}</label
                        >
                        <div class="col-md-8">
                            <select
                                v-model="model.payroll_cycle_number"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'payroll_cycle_number'
                                    )
                                }"
                                @change="fetchVariable(model.payroll_cycle_number)"
                            >
                                <option
                                    :value="null"
                                    disabled
                                    selected
                                    style="display: none;"
                                    >{{ $t('select') }}</option
                                >
                                <option
                                    v-for="(p, index) in payrollCycleNumber"
                                    :key="index"
                                    :value="p.value"
                                    >{{ p.text }}</option
                                >
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('payroll_cycle_number')"
                            >
                                {{ errors.first('payroll_cycle_number') }}
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mb-3 mt-2">
                        <label
                            class="col-md-4 form-col-label control-label required"
                            >{{ $t('payrollTemplate.templateName') }}</label
                        >
                        <div class="col-md-8">
                            <input
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('template_name')
                                }"
                                v-model="model.template_name"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('template_name')"
                            >
                                {{ errors.first('template_name') }}
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mb-3">
                        <label
                            class="col-md-4 form-col-label control-label required"
                            >{{ $t('payrollTemplate.rowStart') }}</label
                        >
                        <div class="col-md-8">
                            <input
                                type="number"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('excel_row_start')
                                }"
                                v-model.number="model.excel_row_start"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('excel_row_start')"
                            >
                                {{ errors.first('excel_row_start') }}
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mb-3">
                        <label
                            class="col-md-4 form-col-label control-label required"
                            >{{ $t('payrollTemplate.columnStart') }}</label
                        >
                        <div class="col-md-8">
                            <input
                                type="number"
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has(
                                        'excel_column_start'
                                    )
                                }"
                                v-model.number="model.excel_column_start"
                            />
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('excel_column_start')"
                            >
                                {{ errors.first('excel_column_start') }}
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mb-3">
                        <label class="col-md-4 form-col-label control-label">{{
                            $t('payrollTemplate.groupBy')
                        }}</label>
                        <div class="col-md-8">
                            <select
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('group_by_column')
                                }"
                                v-model.number="model.group_by_column"
                            >
                                <option :value="null">{{
                                    $t('select')
                                }}</option>
                                <option
                                    v-for="(item,
                                    index) in groupByVariableBounded"
                                    :key="index"
                                    :value="item.value"
                                >
                                    {{ item.text }}
                                </option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('group_by_column')"
                            >
                                {{ errors.first('group_by_column') }}
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mb-3">
                        <label
                            class="col-md-4 form-col-label control-label required"
                            >{{ $t('payrollTemplate.templateType') }}</label
                        >
                        <div class="col-md-8">
                            <select
                                class="form-control"
                                :class="{
                                    'is-invalid': errors.has('template_type')
                                }"
                                v-model.number="model.template_type"
                            >
                                <option :value="null">{{
                                    $t('select')
                                }}</option>
                                <option value="Payroll">Payroll</option>
                                <option value="Others">Others</option>
                            </select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('template_type')"
                            >
                                {{ errors.first('template_type') }}
                            </div>
                        </div>
                    </div>
                    <div class="row form-group mb-3">
                        <label
                            class="col-md-4 form-col-label control-label required"
                            >{{ $t('payrollTemplate.autoId') }}</label
                        >
                        <div class="col-md-8">
                            <ts-checkbox
                                v-model="model.include_auto_number"
                                :disabled="true"
                            ></ts-checkbox>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('include_auto_number')"
                            >
                                {{ errors.first('include_auto_number') }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5 class="tw-py-3 tw-border-b tw-text-blue-700">
                            <b>{{ $t('payrollTemplate.headerReport') }}</b>
                        </h5>
                        <div class="row mt-2">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{
                                        $t('payrollTemplate.isCaption1')
                                    }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <ts-checkbox
                                    v-model="model.is_caption1"
                                ></ts-checkbox>
                            </div>
                        </div>
                        <div class="row tw-my-2">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{ $t('payrollTemplate.caption1') }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.caption1"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has('caption1')
                                    }"
                                    :disabled="!model.is_caption1"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('caption1')"
                                >
                                    {{ errors.first('caption1') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{
                                        $t('payrollTemplate.caption1Cell')
                                    }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.caption1_cell"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'caption1_cell'
                                        )
                                    }"
                                    :disabled="!model.is_caption1"
                                    @input="
                                        model.caption1_cell = $event.target.value.toUpperCase()
                                    "
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('caption1_cell')"
                                >
                                    {{ errors.first('caption1_cell') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{
                                        $t('payrollTemplate.isCaption2')
                                    }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <ts-checkbox
                                    v-model="model.is_caption2"
                                ></ts-checkbox>
                            </div>
                        </div>
                        <div class="row tw-my-2">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{ $t('payrollTemplate.caption2') }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.caption2"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has('caption2')
                                    }"
                                    :disabled="!model.is_caption2"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('caption2')"
                                >
                                    {{ errors.first('caption2') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{
                                        $t('payrollTemplate.caption2Cell')
                                    }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.caption2_cell"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'caption2_cell'
                                        )
                                    }"
                                    :disabled="!model.is_caption2"
                                    @input="
                                        model.caption2_cell = $event.target.value.toUpperCase()
                                    "
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('caption2_cell')"
                                >
                                    {{ errors.first('caption2_cell') }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{
                                        $t('payrollTemplate.isCaption3')
                                    }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <ts-checkbox
                                    v-model="model.is_caption3"
                                ></ts-checkbox>
                            </div>
                        </div>
                        <div class="row tw-my-2">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{ $t('payrollTemplate.caption3') }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.caption3"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has('caption3')
                                    }"
                                    :disabled="!model.is_caption3"
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('caption3')"
                                >
                                    {{ errors.first('caption3') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-4">
                                <label
                                    class="form-col-label control-label ml-2"
                                    >{{
                                        $t('payrollTemplate.caption3Cell')
                                    }}</label
                                >
                            </div>
                            <div class="col-md-8">
                                <input
                                    type="text"
                                    v-model="model.caption3_cell"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': errors.has(
                                            'caption3_cell'
                                        )
                                    }"
                                    :disabled="!model.is_caption3"
                                    @input="
                                        model.caption3_cell = $event.target.value.toUpperCase()
                                    "
                                />
                                <div
                                    class="invalid-feedback"
                                    v-if="errors.has('caption3_cell')"
                                >
                                    {{ errors.first('caption3_cell') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 tw-border-l-4">
                    <variable-list
                        v-model="model.template_detail"
                        @sort="setNewSort"
                    ></variable-list>
                </div>
            </div>
            <div class="ivu-modal-footer">
                <div class="col-md-12 text-right">
                    <ts-button class="mr-2" @click.prevent="$emit('close')">{{
                        $t('cancel')
                    }}</ts-button>
                    <ts-button
                        class="btn-primary"
                        :waiting="saving"
                        @click.prevent="update"
                        v-if="updating"
                    >
                        {{ $t('update') }}</ts-button
                    >
                    <ts-button
                        class="btn-primary"
                        :waiting="saving"
                        @click.prevent="store"
                        v-if="!updating"
                    >
                        {{ $t('save') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import VariableList from './variable-list'
import { isEmpty } from 'lodash'
import { mapState } from 'vuex'

export default {
    name: 'payroll-template-form',
    components: {
        VariableList
    },
    data () {
        return {
            model: {
                template_id: null,
                template_name: null,
                excel_row_start: null,
                excel_column_start: null,
                include_auto_number: true,
                template_type: null,
                group_by_column: null,
                is_caption1: false,
                caption1: null,
                caption1_cell: null,
                is_caption2: false,
                caption2: null,
                caption2_cell: null,
                is_caption3: false,
                caption3: null,
                caption3_cell: null,
                template_detail: [],
                payroll_cycle_number: null
            },
            errors: new Errors(),
            saving: false,
            updating: false,
            loading: false,
            payrollCycleNumber: [
                {
                    value: 1,
                    text: 'First Cycle'
                },
                {
                    value: 2,
                    text: 'Second Cycle'
                }
            ]
        }
    },
    computed: {
        ...mapState('payroll/payrollTemplate', ['edit_data']),
        groupByVariableBounded () {
            return this.$store.state.payroll.payrollTemplate.userVariables
                .filter(v => v.nature === 'System')
                .map(g => ({
                    value: g.display_name,
                    text: g.display_name
                }))
        }
    },
    methods: {
        fetchVariable (payroll_cycle_number) {
            if (!payroll_cycle_number) return
            this.loading = true
            this.$store
                .dispatch('payroll/payrollTemplate/variable', {
                    cycle_number: payroll_cycle_number
                })
                .then(() => {
                    this.loading = false
                    this.setEditData()
                })
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
        },
        store () {
            this.errors = new Errors()
            this.saving = true
            this.$store
                .dispatch('payroll/payrollTemplate/store', this.model)
                .then(response => {
                    this.saving = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('close')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.saving = false
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        update () {
            this.errors = new Errors()
            this.saving = true
            this.$store
                .dispatch('payroll/payrollTemplate/update', {
                    id: this.edit_data.template_id,
                    data: this.model
                })
                .then(response => {
                    this.saving = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('close')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.saving = false
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        setEditData () {
            if (!isEmpty(this.edit_data) || !this.edit_data === undefined) {
                this.updating = true
                this.model = {
                    template_id: this.edit_data.template_id,
                    template_name: this.edit_data.template_name,
                    excel_row_start: this.edit_data.excel_row_start,
                    excel_column_start: this.edit_data.excel_column_start,
                    include_auto_number: this.edit_data.include_auto_number,
                    template_type: this.edit_data.template_type,
                    group_by_column: this.edit_data.group_by_column
                        ? this.edit_data.group_by_column
                        : '',
                    is_caption1: this.edit_data.is_caption1,
                    caption1: this.edit_data.caption1,
                    caption1_cell: this.edit_data.caption1_cell,
                    is_caption2: this.edit_data.is_caption2,
                    caption2: this.edit_data.caption2,
                    caption2_cell: this.edit_data.caption2_cell,
                    is_caption3: this.edit_data.is_caption3,
                    caption3: this.edit_data.caption3,
                    caption3_cell: this.edit_data.caption3_cell,
                    template_detail: this.edit_data.details,
                    payroll_cycle_number: this.edit_data.payroll_cycle_number
                }
            }
        },
        setNewSort (value) {
            if (value.length > 0) {
                this.model.template_detail = value
            }
        },
        clearInput () {
            this.model.template_id = null
            this.model.template_name = null
            this.model.excel_row_start = null
            this.model.excel_column_start = null
            this.model.include_auto_number = true
            this.model.template_type = null
            this.model.group_by_column = null
            this.model.is_caption1 = false
            this.model.caption1 = null
            this.model.caption1_cell = null
            this.model.is_caption2 = false
            this.model.caption2 = null
            this.model.caption2_cell = null
            this.model.is_caption3 = false
            this.model.caption3 = null
            this.model.caption3_cell = null
            this.model.template_detail = []
            this.model.payroll_cycle_number = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'REPORT CUSTOMIZATION',
                desc: not.text
            })
        }
    },
    watch: {
        'model.is_caption1': function (value) {
            if (!value) {
                this.model.caption1 = ''
                this.model.caption1_cell = ''
            }
        },
        'model.is_caption2': function (value) {
            if (!value) {
                this.model.caption2 = ''
                this.model.caption2_cell = ''
            }
        },
        'model.is_caption3': function (value) {
            if (!value) {
                this.model.caption3 = ''
                this.model.caption3_cell = ''
            }
        }
    }
}
</script>
